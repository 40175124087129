<template>
  <div class="root">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 text-center">
          Address: {{addr}}
        </div>
        <div class="col-sm-12 text-center" style="margin-top: 10px">
          Balance: {{balance}} ILG
        </div>
        <div class="col-sm-12 text-center" style="margin-top: 10px">
          Stake: {{stake}} ILG
        </div>
        <div class="col-sm-12 text-center" style="margin-top: 10px">
          <button type="button" class="btn btn-primary" @click="update()">Refresh</button>
        </div>
  
        <div class="col-sm-12 text-center">
        <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Amount</th>
              <th scope="col">Timestamp</th>
              <th scope="col">Hash</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="tx in transactions" :key="tx.hash">
              <th scope="row">{{tx.nonce}}</th>
              <td>{{tx.from | shortHash}}</td>
              <td>{{tx.to || '0x0000000000000000000000000000000000000000' | shortHash}}</td>
              <td> 
		<a v-if="txDirection(tx.from) && txDirection(tx.to)">←</a> 
		<a v-if="txDirection(tx.from) && !txDirection(tx.to)">→</a> 
		<a v-if="txDirection(tx.to) && !txDirection(tx.from)">⇄</a> 
		{{tx.value/1000000000000000000}}
		</td>
              <td>{{new Date(tx.timeStamp*1000).toLocaleString()}}</td>
              <td>{{tx.hash | shortHash }}</td>
            </tr>
          </tbody>
        </table>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers'

export default {
  name: 'Index',
  data () {
    return {
      addr: '',
      balance: 0,
      stake: 0,
      transactions: []
    }
  },
  methods: {
    txDirection (a) {
      return a.trim().toLowerCase() === this.addr.trim().toLowerCase()
    },
    update: function() {
      const addrRegex = /^0x[a-fA-F0-9]{40}$/
      console.log(window.location.pathname.split("/").pop())
      if (!addrRegex.test(window.location.pathname.split("/").pop())) {
        console.log(this)
        window.alert('Invalid address')
        return
      }
      const baseUrl = process.env.NODE_ENV === 'production'
	? '//www.ilgonexplorer.com'
	:'//explorer.ilgoindev.e-diploma.org'
      // Get balance
      fetch(`${baseUrl}/api?module=account&action=balance&address=${this.addr}`)
      .then((res) => res.json())
      .then((res) => {
        this.balance = res.result/1000000000000000000
      })

      // Get last transactions
      fetch(`${baseUrl}/api?module=account&action=txlist&address=${this.addr}`)
      .then((res) => res.json())
      .then((res) => {
        this.transactions = res.result.slice(0, 100)
      })
      
      // Get stake from contract
      const ercAbi = [
      {
        "inputs": [],
        "name": "name",
        "outputs": [
        {
          "internalType": "string",
          "name": "_name",
          "type": "string"
        }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "symbol",
        "outputs": [
        {
          "internalType": "string",
          "name": "_symbol",
          "type": "string"
        }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        }
        ],
        "name": "tokenURI",
        "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
        ],
        "stateMutability": "view",
        "type": "function"
      }
      ]
      const provider = process.env.NODE_ENV === 'production'
	? new ethers.providers.JsonRpcProvider('https://mainnet-rpc.ilgonwallet.com/')
	: new ethers.providers.JsonRpcProvider('https://wallet.ilgoindev.e-diploma.org/rpc/eth/')
      const contract = new ethers.Contract('0x6AA580a52c60653AD32D35171b019aBc497A94E0', ercAbi, provider)
      contract.name().then((res) => {
        this.stake = res
      })
    }
  },
  mounted () {
    this.addr = window.location.pathname.split("/").pop().trim()
    this.update()
  },
  filters: {
    shortHash: function (val) {
      return val.slice(0,8) + '...' + val.slice(-6)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
